<template>
  <div>
    <h1>Bank Holidays</h1>
    <p>
      The following shows upcoming bank holidays in the UK with the number of
      days between them.
    </p>
    <h2>England and Wales</h2>
    <HolidayScroller :events="holidays['england-and-wales']" />
    <h2>Scotland</h2>
    <HolidayScroller :events="holidays['scotland']" />
    <h2>Northern Ireland</h2>
    <HolidayScroller :events="holidays['northern-ireland']" />
  </div>
</template>

<script>
import axios from "axios";
import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);
import HolidayScroller from "./components/HolidayScroller.vue";

export default {
  name: "App",
  data() {
    return {
      holidays: {},
    };
  },
  methods: {
    async getHolidays() {
      try {
        const response = await axios.get(
          "https://www.gov.uk/bank-holidays.json"
        );

        this.holidays = {
          "england-and-wales": this.addDaysTillNextEvent(
            this.filterEventsToFuture(
              this.addDayjsObjToEvents(
                response.data["england-and-wales"].events
              )
            )
          ),
          scotland: this.addDaysTillNextEvent(
            this.filterEventsToFuture(
              this.addDayjsObjToEvents(response.data["scotland"].events)
            )
          ),
          "northern-ireland": this.addDaysTillNextEvent(
            this.filterEventsToFuture(
              this.addDayjsObjToEvents(response.data["northern-ireland"].events)
            )
          ),
        };
      } catch (error) {
        console.log(error);
      }
    },
    addDayjsObjToEvents(events) {
      events.forEach((event) => {
        event.dayjs = dayjs(event.date);
      });

      return events;
    },
    filterEventsToFuture(events) {
      return events.filter((event) =>
        event.dayjs.isAfter(dayjs().subtract(1, "day"))
      );
    },
    addDaysTillNextEvent(events) {
      const today = dayjs();

      if (!events[0]?.dayjs.isSame(today, 'day')) {
        const todayEvent = {
          title: 'Today',
          date: today.format('YYYY-MM-DD'),
          dayjs: today,
          notes: '',
          bunting: false
        };

        events.unshift(todayEvent);
      }

      for (let i = 0; i < events.length - 1; i++) {
        events[i].daysTillNextEvent = Math.abs(
          events[i].dayjs.diff(events[i + 1].dayjs, "day") + 1
        );
      }

      return events;
    },
  },
  created() {
    this.getHolidays();
  },
  components: {
    HolidayScroller,
  },
};
</script>

<style>
body {
  background: #FEFCF3;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  margin-top: 60px;
}

h2 {
  font-size: 2.5rem;
}

.event-scroller {
  margin-bottom: 4rem;
}
</style>
