<template>
  <div class="event-scroller">
    <div v-for="tile in tiles" :key="tile" class="event" :class="[tile.type]">
      <div class="title">{{ tile.title }}</div>
      <div class="date">{{ tile.description }}</div>
    </div>
  </div>
</template>

<script>
import * as dayjs from "dayjs";

export default {
  name: "HolidayScroller",
  props: {
    events: {},
  },
  computed: {
    tiles() {
      if (!this.events) {
        return [];
      }

      const tiles = [];

      const today = dayjs();

      for (const event of this.events) {
        if (event.dayjs.isSame(today, 'day')) {
          tiles.push({
            title: event.title,
            description: event.dayjs.format("ddd Do MMMM YYYY"),
            type: "today",
          });
        } else {
          tiles.push({
            title: event.title,
            description: event.dayjs.format("ddd Do MMMM YYYY"),
            type: "holiday",
          });
        }

        if (event.daysTillNextEvent == null || typeof (event.daysTillNextEvent) === 'undefined' || event.daysTillNextEvent == 0) {
          continue;
        }

        tiles.push({
          title: event.daysTillNextEvent,
          description: "days",
          type: "work",
        });
      }

      return tiles;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.event-scroller {
  display: flex;
  overflow: scroll hidden;
  scroll-behavior: smooth;
}

.event {
  flex: 0 0 10rem;
  height: 9rem;
  margin: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #333;
  background: #F5EBE0;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.event .title {
  height: 60%;
  font-size: 1.15rem;
}

.event .date {
  height: 40%;
  font-size: 1.1rem;
}

.holiday {
  background: #A8D1D1;
}

.work .title {
  font-size: 3rem;
}

.today {
  background: #9EA1D4;
}
</style>
